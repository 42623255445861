import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aliados-carousel',
  templateUrl: './aliados-carousel.component.html',
  styleUrls: ['./aliados-carousel.component.scss']
})
export class AliadosCarouselComponent implements OnInit {

  title:string = "Aliados";
  listaAliados:any = [
    {nombre: "FNG", ruta: "assets/img/ablcapital/empresas/FNG Logo.png", enlace: "https://www.fng.gov.co/EN"},
    {nombre: "Bancoldex", ruta: "assets/img/ablcapital/empresas/bancoldex logo.png", enlace: "https://www.bancoldex.com/"},
    {nombre: "Ventures", ruta: "assets/img/ablcapital/empresas/ventures.png", enlace: "http://ventures.com"},
    {nombre: "Superintendencia de sociedades", ruta: "assets/img/ablcapital/empresas/logo superintendencia de sociedades.png", enlace: "http://superintendencia-sociedades.com"},
    {nombre: "Fintech", ruta: "assets/img/ablcapital/empresas/fintech.png", enlace: "http://Fintech.com"}
  ];

  constructor() { }

  ngOnInit() {
  }

}
