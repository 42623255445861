import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-privacidad',
  templateUrl: './politicas-privacidad.component.html',
  styleUrls: ['./politicas-privacidad.component.scss']
})
export class PoliticasPrivacidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
