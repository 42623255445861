import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss']
})
export class BeneficiosComponent implements OnInit {

  listaBeneficios:any = [
    {titulo: "CAPITAL", titulo2: "DE TRABAJO", icono: "icon-tools", texto: "Incremente las oportunidades de atender sus obligaciones empresariales con nuestro Préstamo de Capital de Trabajo, el cual le otorga multiples opciones de liquidez en el corto plazo."},
    {titulo: "FACTORING Y/0 CESIÓN", titulo2: "DE DERECHOS ECONÓMICOS", icono: "icon-layers", texto: "Potencialice el desempeño, rendimiento y expansión de su negocio por medio del descuento de sus facturas o cesión de sus contratos u ordenes de compra."},
    {titulo: "APLICA AHORA", titulo2: "FINANCIACIÓN PARA EMPRESAS", icono: "icon-hotairballoon", texto: "Tenga rápido acceso a crédito para capital de trabajo"}
  ];

  constructor() { }

  ngOnInit() {
  }

}
