import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderComponent } from './components/slider/slider.component';
import { HeaderComponent } from './components/base/header/header.component';
import { FooterComponent } from './components/base/footer/footer.component';
import { SomosComponent } from './components/somos/somos.component';
import { OpcionesComponent } from './components/opciones/opciones.component';
import { BlogComponent } from './components/blog/blog.component';
import { SolicitudesComponent } from './components/solicitudes/solicitudes.component';
import { PrestamoComponent } from './components/solicitudes/prestamo/prestamo.component';
import { FactoringComponent } from './components/solicitudes/factoring/factoring.component';
import { AliadosComponent } from './components/solicitudes/aliados/aliados.component';
import { BeneficiosComponent } from './components/beneficios/beneficios.component';
import { HistoriasComponent } from './components/historias/historias.component';
import { AliadosCarouselComponent } from './components/base/aliados-carousel/aliados-carousel.component';
import { NavComponent } from './components/base/nav/nav.component';
import { CallToActionComponent } from './components/base/call-to-action/call-to-action.component';
import { QuienesSomosComponent } from './components/somos/quienes-somos/quienes-somos.component';
import { HomeComponent } from './components/home/home.component';
import { ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { ConsultoriaComponent } from './components/consultoria/consultoria.component';
import { ContactoComponent } from './components/contacto/contacto.component';

// Servicios
import { ContactoService } from './servicios/contacto.service';
import { BlogService } from './servicios/blog.service';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { DetailComponent } from './components/blog/detail/detail.component';
import { BlogPipesPipe } from './pipes/blog-pipes.pipe';
import { FinanciacionComponent } from './components/financiacion/financiacion.component';
import { CapitalDeTrabajoComponent } from './components/financiacion/capital-de-trabajo/capital-de-trabajo.component';
import { OrdenesDeCompraComponent } from './components/financiacion/ordenes-de-compra/ordenes-de-compra.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { PoliticasPrivacidadComponent } from './components/politicas-privacidad/politicas-privacidad.component';
import { SolicitudFacebookComponent } from './components/solicitud-facebook/solicitud-facebook.component';
import { SolicitudLinkedinComponent } from './components/solicitud-linkedin/solicitud-linkedin.component';
import { ContactoEmpresasComponent } from './components/contacto-empresas/contacto-empresas.component';
import { HomeCarComponent } from './components/home-car/home-car.component';
import { SlideCarComponent } from './components/slide-car/slide-car.component';
import { CallToActionVehiculosComponent } from './components/call-to-action-vehiculos/call-to-action-vehiculos.component';

@NgModule({
  declarations: [
    AppComponent,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    SomosComponent,
    OpcionesComponent,
    BlogComponent,
    SolicitudesComponent,
    PrestamoComponent,
    FactoringComponent,
    AliadosComponent,
    BeneficiosComponent,
    HistoriasComponent,
    AliadosCarouselComponent,
    NavComponent,
    CallToActionComponent,
    QuienesSomosComponent,
    HomeComponent,
    ConsultoriaComponent,
    ContactoComponent,
    NotFoundComponent,
    DetailComponent,
    BlogPipesPipe,
    FinanciacionComponent,
    CapitalDeTrabajoComponent,
    OrdenesDeCompraComponent,
    TerminosComponent,
    PoliticasPrivacidadComponent,
    SolicitudFacebookComponent,
    SolicitudLinkedinComponent,
    ContactoEmpresasComponent,
    HomeCarComponent,
    SlideCarComponent,
    CallToActionVehiculosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [ContactoService, BlogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
