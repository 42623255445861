import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  dataForm:any;
  apiURL: string = 'https://www.abl-capital.com/clients';

  constructor(private http: HttpClient) { }

  sendDataContact(data:any): Observable<any> {
    this.dataForm = {
      id: 23,
      contacto : data
    }
    return this.http.post<any>(`${this.apiURL}/externo/extern/proc_ext.php`, this.dataForm);
  }

}
