import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historias',
  templateUrl: './historias.component.html',
  styleUrls: ['./historias.component.scss']
})
export class HistoriasComponent implements OnInit {


  listaComentarios:any = [
    {nombre: "Anónimo", cargo: "Gerente general", imagen: "assets/img/ablcapital/user.jpg", mensaje: "No estaba recibiendo el suficiente capital por parte de los bancos a pesar de contar con un crecimiento sostenido generando utilidades año a año y en ABL Capital me ayudaron con un préstamo de capital de trabajo."},
    {nombre: "Anónimo", cargo: "Gerente Financiero", imagen: "assets/img/ablcapital/user.jpg", mensaje: "Necesitaba una financiación urgente para un pedido grande que me salió y ABL Capital me ayudaba muy rápidamente lo que hizo que pudiera cerrar mi contrato."},
    {nombre: "Anónimo", cargo: "Principal accionista", imagen: "assets/img/ablcapital/user.jpg", mensaje: "Gracias a las “Fintech” ahora si tengo opciones de financiación."},
  ];

  constructor() { }

  ngOnInit() {
  }

}
