import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opciones',
  templateUrl: './opciones.component.html',
  styleUrls: ['./opciones.component.scss']
})
export class OpcionesComponent implements OnInit {

  listaOpciones:any = [
    {titulo: "CAPITAL DE TRABAJO", imagen: "assets/img/ablcapital/capital-trabajo.jpg", texto: "Crédito comercial con el objetivo de financiar capital de trabajo (cartera, inventarios, proveedores)."},
    {titulo: "FACTORING", imagen: "assets/img/ablcapital/factoring.jpg", texto: "Le generamos liquidez a su negocio descontando sus facturas hoy."},
    {titulo: "CESIÓN DERECHOS ECONÓMICOS", imagen: "assets/img/ablcapital/cs.jpg", texto: "Cesión de derechos económicos de un contrato del cliente."},
    {titulo: "PRENDA", imagen: "assets/img/ablcapital/prenda.jpg", texto: "Crédito de capital de trabajo donde se utiliza una prenda (ej: vehículo) como garantía."}
  ];

  constructor() { }

  ngOnInit() {
  }

}
