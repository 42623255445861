import { Component, OnInit } from '@angular/core';

import { BlogService } from 'src/app/servicios/blog.service';
import { Blog } from '../../interfaces/blog'


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  title = 'ABL Capital | Noticias';
  blogs:any;
  public dummyElem = document.createElement('DIV');

  constructor(
    
    
    private bgService: BlogService
  ) { 

    
  }

  ngOnInit() {
    
    this.bgService.getAll()
      .subscribe(data => {
        this.blogs = data;
      });

  }

  decode(text: string): string {    
    var ret:string = "";

    this.dummyElem.innerHTML = text;
    document.body.appendChild(this.dummyElem);
    ret = this.dummyElem.textContent; // just grap the decoded string which contains the desired HTML tags
    document.body.removeChild(this.dummyElem);

    return ret;
  } 


  

}
