import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { BlogPipesPipe } from "../../../pipes/blog-pipes.pipe";
import { BlogService } from 'src/app/servicios/blog.service';

import { Blog } from '../../../interfaces/blog';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  public dummyElem = document.createElement('DIV');
  descripcionPipe:any;
  id:any;
  blog:any;  

  constructor(
      private _route: ActivatedRoute,
      
      private bgService: BlogService) { 
    this.id = this._route.snapshot.paramMap.get('id');

    
  }



  decode(text: string): string {    
    var ret:string = "";
    this.dummyElem.innerHTML = text;
    document.body.appendChild(this.dummyElem);
    ret = this.dummyElem.textContent; // just grap the decoded string which contains the desired HTML tags
    document.body.removeChild(this.dummyElem);

    return ret;
  }   

  ngOnInit() {

    this.bgService.getIDBlog(this.id)
      .subscribe(data => {
        this.blog = data;
        console.log(data);
        // this.meta.addTag({ name: 'noticias', content: data.title  });
      });

  }

}
