import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Blog } from '../interfaces/blog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  dataForm:any;
  apiURL: string = 'https://www.abl-capital.com/cms';

  constructor(private http: HttpClient) { }

  getAll(): Observable<Blog[]> {
    return this.http.get<Blog[]>(`${this.apiURL}/externo/extern/blog-services.php?id=1`);
  }

  getIDBlog(data:any): Observable<Blog[]> {
    return this.http.get<Blog[]>(`${this.apiURL}/externo/extern/blog-services.php?id=2&idBlog=${data}`);
  }

  getFilter(data:any): Observable<Blog[]> {
    return this.http.get<Blog[]>(`${this.apiURL}/externo/extern/blog-services.php?id=3&s=${data}`);
  }

}
