import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordenes-de-compra',
  templateUrl: './ordenes-de-compra.component.html',
  styleUrls: ['./ordenes-de-compra.component.scss']
})
export class OrdenesDeCompraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
