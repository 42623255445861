import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ContactoService } from 'src/app/servicios/contacto.service';

@Component({
  selector: 'app-contacto-empresas',
  templateUrl: './contacto-empresas.component.html',
  styleUrls: ['./contacto-empresas.component.scss']
})
export class ContactoEmpresasComponent  implements AfterViewInit {
  title = 'ABL Capital | Contacto';
  mensajeForm:string = "";
  mensajeFormOk:string = "";


  contactoForm = this.fb.group({
    name: ['', Validators.required],
    phone: ['', Validators.required],
    emailText: ['', Validators.required],
    asunto: ['', Validators.required],
    message: ['', Validators.required]
  });

@ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  map: google.maps.Map;
  lat = 4.7042912;
  lng = -74.04206359999999;

  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
   center: this.coordinates,
   zoom: 16
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  });


  constructor(
    private titleService: Title,
    private meta: Meta,
    private fb: FormBuilder,
    private contactoServices: ContactoService
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, 
    this.mapOptions);
    this.marker.setMap(this.map);
  }

  onSubmitContacto() {
    if(this.validarForm(this.contactoForm.value)){

      this.contactoServices.sendDataContact(this.contactoForm.value)
      .subscribe(data => {
        console.log(data);
      });

      this.contactoForm.reset();
      this.mensajeFormOk = "Se ha enviado el mensaje, pronto nos pondremos en contacto.";      
    }    
  }

  validarForm(form:any) {
    
    if(form.name.length != null && form.name.length <= 5){
      this.mensajeForm = "Ingrese su nombre completo";
      return false;
    }

    if(form.phone.length != null && form.phone.length <= 5){
      this.mensajeForm = "Ingrese su número teléfonico";
      return false;
    }

    if(form.emailText.length != null && form.emailText.length <= 5){
      this.mensajeForm = "Ingrese su correo eléctronico";
      return false;
    }

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.emailText))
    {
      this.mensajeForm = "";
    }else{
      this.mensajeForm = "Ingrese su correo eléctronico valido";
      return false;
    }

    if(form.asunto.length != null && form.asunto.length <= 5){
      this.mensajeForm = "Ingrese un asunto";
      return false;
    }

    if(form.message.length != null && form.message.length <= 5){
      this.mensajeForm = "Escribanos un mensaje con su petición";
      return false;
    }        
    return true;
  }

}
