import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-to-action-vehiculos',
  templateUrl: './call-to-action-vehiculos.component.html',
  styleUrls: ['./call-to-action-vehiculos.component.scss']
})
export class CallToActionVehiculosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
