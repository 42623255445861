import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuienesSomosComponent } from './components/somos/quienes-somos/quienes-somos.component';
import { BlogComponent } from './components/blog/blog.component';
import { HomeComponent } from './components/home/home.component';
import { PrestamoComponent } from './components/solicitudes/prestamo/prestamo.component';
import { AliadosComponent } from './components/solicitudes/aliados/aliados.component';
import { FactoringComponent } from './components/solicitudes/factoring/factoring.component';
import { ConsultoriaComponent } from './components/consultoria/consultoria.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DetailComponent } from "./components/blog/detail/detail.component";
import { FinanciacionComponent } from "./components/financiacion/financiacion.component";
import { CapitalDeTrabajoComponent } from "./components/financiacion/capital-de-trabajo/capital-de-trabajo.component";
import { OrdenesDeCompraComponent } from "./components/financiacion/ordenes-de-compra/ordenes-de-compra.component";
import { TerminosComponent } from './components/terminos/terminos.component';
import { PoliticasPrivacidadComponent } from './components/politicas-privacidad/politicas-privacidad.component';
import { SolicitudFacebookComponent } from './components/solicitud-facebook/solicitud-facebook.component';
import { SolicitudLinkedinComponent } from './components/solicitud-linkedin/solicitud-linkedin.component';
import { ContactoEmpresasComponent } from './components/contacto-empresas/contacto-empresas.component';
import { HomeCarComponent } from './components/home-car/home-car.component';




const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'quienes-somos', component: QuienesSomosComponent },
  { path: 'consultoria', component: ConsultoriaComponent },
  { path: 'noticias', component: BlogComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'contacto-empresas', component: ContactoEmpresasComponent },
  { path: 'solicita-prestamo', component: PrestamoComponent },
  { path: 'solicita-factoring', component: FactoringComponent },
  { path: 'solicita-prestamo-aliado', component: AliadosComponent },
  { path: 'factoring-pymes', component: FinanciacionComponent},
  { path: 'capital-de-trabajo', component: CapitalDeTrabajoComponent},
  { path: 'financiacion-pymes', component: OrdenesDeCompraComponent},
  { path: 'noticias/noticia/:id/:name', component: DetailComponent },
  { path: 'terminos-y-condiciones' , component: TerminosComponent},
  { path: 'politicas-de-privacidad' , component: PoliticasPrivacidadComponent},
  { path: 'solicitud-prestamo-facebook' , component: SolicitudFacebookComponent},
  { path: 'solicitud-prestamo-linkedIn' , component: SolicitudLinkedinComponent},
  { path: 'solicita-prestamo-vehiculo' , component: HomeCarComponent},
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

