import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormFactoringService {
  apiURL: string = 'https://www.abl-capital.com/clients';
  formPersonal:any;
  dataForm:any;

  constructor(private http: HttpClient) { }

  sendDataPersonal(data:any) {
    this.formPersonal = data;
  }

  sendDataComercial(data:any): Observable<any> {
    // console.warn(data);
    this.dataForm = {
      id: 24,
      personal : this.formPersonal,
      comercial : data
    }
    return this.http.post<any>(`${this.apiURL}/externo/extern/proc_ext.php`, this.dataForm);
  }

}
