import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  title = 'ABL Capital: Financiación para Empresas en Crecimiento';

  constructor(
    private titleService: Title,
    private meta: Meta
  ) { }

  ngOnInit() {

    this.titleService.setTitle(this.title);
  	this.meta.addTag({name: 'keywords', content: 'Créditos, Préstamos, Préstamos PYMES, Empresas Colombianas, capital de trabajo, necesidades financieras, fondear, capital, empresas,trabajo,financiación'});
    this.meta.addTag({name: 'description', content: 'Créditos, Préstamos, Capital de trabajo, Factoring, cesión derechos economicos, prenda y opciones de inversión.'});
    this.meta.addTag({name: 'author', content: 'ABL Capital'});
    this.meta.addTag({name: 'robots', content: 'index, follow'});

  }

}
