import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';



@Component({
  selector: 'app-aliados',
  templateUrl: './aliados.component.html',
  styleUrls: ['./aliados.component.scss']
})
export class AliadosComponent implements OnInit {

  alidosForm = this.fb.group({
    name: ['', Validators.required],
    phone: ['', Validators.required],
    emailText: ['', Validators.required],
    message: ['', Validators.required]
  });

  alidosForm2 = this.fb.group({
    name: ['', Validators.required],
    phone: ['', Validators.required],
    emailText: ['', Validators.required],
    message: ['', Validators.required]
  });
  

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
  }

}
