import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl  } from '@angular/forms';
import { FormPrestamoService } from 'src/app/servicios/form-prestamo.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-prestamo',
  templateUrl: './prestamo.component.html',
  styleUrls: ['./prestamo.component.scss']
})
export class PrestamoComponent implements OnInit {

  // mensajeForm:string = "";
  // mensajeComercial:string = "";
  // mensajeFormOk:string = "";
  // errorNit:boolean = false;

  // tab1:boolean = true;
  // tab2:boolean = false;

  // popup1:boolean = false;
  // popup2:boolean = false;

  // formularioPersonal = new FormGroup({
  //   id: new FormControl(''),
  //   canal: new FormControl(''),
  //   nombre_representante: new FormControl(''),
  //   tipo_documento: new FormControl(''),
  //   num_documento: new FormControl(''),
  //   celular: new FormControl(''),
  //   correo: new FormControl('')
  // });

  // formularioComercial = new FormGroup({
  //   id: new FormControl(''),
  //   razon_social: new FormControl(''),
  //   nit_empresa: new FormControl(''),
  //   ciudad: new FormControl(''),
  //   sitio_web: new FormControl(''),
  //   destino_credito: new FormControl(''),
  //   monto_indi_credito: new FormControl(''),
  //   plazo_credito: new FormControl(''),
  //   documento_camara_comercio: new FormControl(''),
  //   terminos_condiciones: new FormControl('')
  // });

  constructor(
    private FormPrestamoService: FormPrestamoService
  ) { }

  ngOnInit() {
  }

  // cerrarPop(){
  //   this.popup1 = false;
  //   this.popup2 = false;
  // }

  // mostrarPopUp1(){
  //   this.popup1 = true;
  //   this.popup2 = false;
  // }

  // mostrarPopUp2(){
  //   this.popup1 = false;
  //   this.popup2 = true;
  // }

  // onKeyNit(event: any) { // without type info
  //   let valNit = event.target.value;
  //   let validoPunto = valNit.indexOf("."); 
  //   if(validoPunto != -1){
  //     this.errorNit = true;
  //   }else{
  //     this.errorNit = false;
  //   }
  // }

  // onSubmitPersonal() { 
  //   if(this.validarFormPersonal(this.formularioPersonal.value))
  //   {
    
  //     this.FormPrestamoService.sendDataPersonal(this.formularioPersonal.value);
  //     this.tab1 = false;
  //     this.tab2 = true;
  //   }    
  // }

  // validarFormPersonal(form:any) {
    
  //   if(form.nombre_representante.length <= 5){
  //     this.mensajeForm = "Ingrese el nombre del representante legal";
  //     return false;
  //   }
    
  //   if(form.tipo_documento.length == ""){
  //     this.mensajeForm = "Seleccione el tipo de documento";
  //     return false;
  //   }

  //   if(form.celular.length <= 5){
  //     this.mensajeForm = "Ingrese su número celular";
  //     return false;
  //   }

  //   if(form.correo.length <= 5){
  //     this.mensajeForm = "Ingrese su correo eléctronico";
  //     return false;
  //   }

  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.correo))
  //   {
  //     this.mensajeForm = "";
  //   }else{
  //     this.mensajeForm = "Ingrese un correo eléctronico valido";
  //     return false;
  //   }
      
  //   return true;
  // }


  // onSubmitComercial() {
  //   if(this.validarFormComercial(this.formularioComercial.value))
  //   {
      
  //     this.FormPrestamoService.sendDataComercial(this.formularioComercial.value)
  //     .subscribe(data => {
  //       console.log(data);
  //     });

  //     this.formularioPersonal.reset();
  //     this.formularioComercial.reset();
  //     this.mensajeFormOk = "Se ha enviado el mensaje, pronto nos pondremos en contacto.";      
  //   }    
  // }

  // validarFormComercial(form:any) {
    
  //   if(form.razon_social.length <= 5){
  //     this.mensajeComercial = "Ingrese la razón social";
  //     return false;
  //   }

  //   if(form.nit_empresa.length <= 5){
  //     this.mensajeComercial = "Ingrese el nit de la empresa";
  //     return false;
  //   }

  //   if(form.ciudad.length <= 2){
  //     this.mensajeComercial = "Ingrese su ciudad";
  //     return false;
  //   }

  //   if(form.destino_credito.length == 0){
  //     this.mensajeComercial = "Seleccione el destino de su crédito";
  //     return false;
  //   }

  //   if(form.monto_indi_credito.length == 0){
  //     this.mensajeComercial = "Seleccione el monto indicativo";
  //     return false;
  //   }

  //   if(form.plazo_credito.length == 0){
  //     this.mensajeComercial = "Seleccione el plazo deseado";
  //     return false;
  //   }

  //   if(form.terminos_condiciones == 0){
  //     this.mensajeComercial = "Acepter los terminos y condiciones";
  //     return false;
  //   }
      
  //   return true;
  // }

}
