import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitud-linkedin',
  templateUrl: './solicitud-linkedin.component.html',
  styleUrls: ['./solicitud-linkedin.component.scss']
})
export class SolicitudLinkedinComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
