import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-somos',
  templateUrl: './somos.component.html',
  styleUrls: ['./somos.component.scss']
})
export class SomosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
